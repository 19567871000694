import React from 'react' 
import {  graphql } from 'gatsby' 
import parse from "html-react-parser"
import Layout from '../components/layout'
import Seo from "../components/seo"



const ArticleTemplate = ({ data: { articleArray } }) => {
  // const featuredImage = {
  //   fluid: article.featuredImage?.node?.localFile?.childImageSharp?.fluid,
  //   alt: article.featuredImage?.node?.alt || ``,
  // }
  //console.log("articleArray", articleArray);
  const article = articleArray.edges[0].node;
   //console.log("article", article);
  //console.log("myposts=",myposts);

  let articlecontent = article.ArticleContent;
  const removeStr = 'https?://www.findbestwebhosting.com/images/' //variable
  const regex = new RegExp(removeStr, 'g') // correct way
  articlecontent = articlecontent.replace(regex, '/imagesfbwh/') // it works
  

  return (
    <Layout>
    <Seo title={article.PageTitle} description={article.ShortDescription} />

      <article
        className="blog-article"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{ (article.Title)}</h1>
        </header>
        <section>{ (article.description)}</section>

 
        {parse( articlecontent )} 

        <footer>
          {/* 
          id: {article.id} <br/>
          slug: {article.name}
        */}
        </footer>
      </article>
    </Layout>
  )
}
 
export default ArticleTemplate 

export const pageQuery = graphql`
  query ArticleById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: Int!
  ) {
    # selecting the current article by id
    articleArray: allMysqlArticles(filter: { articleid: { eq: $id } }) {
      edges {
        node {
            articleid
            ArticleContent
            Title
            ShortDescription
            userid
            dateadded
            datemodified
            categoryid
            sort
            ArticleIntro
            PageTitle
        }
      }
    }
  }
`
